import React from 'react';

type PropTypes = {
  color?: string;
  className?: string;
  style?: React.CSSProperties;
};

const MenuIcon = ({ color = 'white', className, style }: PropTypes): JSX.Element => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} style={style}>
    <path d="M4.15039 17.7173H20.1504C20.7004 17.7173 21.1504 17.2915 21.1504 16.771C21.1504 16.2504 20.7004 15.8246 20.1504 15.8246H4.15039C3.60039 15.8246 3.15039 16.2504 3.15039 16.771C3.15039 17.2915 3.60039 17.7173 4.15039 17.7173ZM4.15039 12.9854H20.1504C20.7004 12.9854 21.1504 12.5596 21.1504 12.0391C21.1504 11.5186 20.7004 11.0927 20.1504 11.0927H4.15039C3.60039 11.0927 3.15039 11.5186 3.15039 12.0391C3.15039 12.5596 3.60039 12.9854 4.15039 12.9854ZM3.15039 7.30719C3.15039 7.82769 3.60039 8.25356 4.15039 8.25356H20.1504C20.7004 8.25356 21.1504 7.82769 21.1504 7.30719C21.1504 6.78668 20.7004 6.36081 20.1504 6.36081H4.15039C3.60039 6.36081 3.15039 6.78668 3.15039 7.30719Z" fill={color} />
  </svg>
);

export default MenuIcon;
